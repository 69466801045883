<template>
  <div id="news">
    <Header />
    <div class="bannner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/other/news_banner_pc.jpg"
        alt=""
        srcset=""
      />
    </div>

    <div class="swiper_box">
      <div class="swiper_inside">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in banner_list" :key="index">
            <div class="banner_box" @click="toDetail(1)">
              <img :src="item.img_pc" alt="" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>

    <div class="list">
      <div
        class="list_inside"
        v-for="(item, index) in current_list"
        :key="index"
        @click="news_detail(item)"
      >
        <div class="list_item">
          <div class="date_container">
            <div>{{ item.day }}</div>
            <div>{{ item.yyyy_mm }}</div>
          </div>
          <div class="img_box">
            <img :src="item.img_pc" alt="" />
          </div>
          <div class="text_content">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="content">
              {{ item.desc }}
            </div>
            <div class="more_btn">查看更多 ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        :current-page="current"
        :page-size="5"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <PCConncatUs />
    <Footer />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PCConncatUs from "@/components/PCConncatUs.vue";

import { queryNewsList,queryNewsBannerList } from "@/api/index";
export default {
  name: "News",
  components: {
    swiper,
    swiperSlide,
    Header,
    Footer,
    PCConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
      list: [],
      banner_list: [],
      current: 1,
      current_list: [],
      total: 25,
    };
  },
  methods: {
    toDetail(index) {},
    news_detail(item) {
      if (item.link != "") {
        window.open(item.url);
      } else {
        this.$router.push("/pc/test");
      }

      /* window.location.href = item.link */
    },
    handleCurrentChange(val) {
      this.current = val;
      this.current_list = this.list.slice(
        5 * (this.current - 1),
        5 * (this.current - 1) + 5
      );
    },
    queryNewsList() {
      queryNewsList({
        page: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.list.forEach(item=>{
            item.yyyy_mm = item.date.split('-')[0] + "-" + item.date.split('-')[1]
            item.day = item.date.split('-')[2]
          })

          this.current_list = this.list.slice(0, 5);
          this.total = this.list.length;
        }
      });
    },
    queryNewsBannerList(){
      queryNewsBannerList({
        page: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.data.code == 200) {
          this.banner_list = res.data.data.data
        }
      });
    }
  },
  created() {
    this.queryNewsList();
    this.queryNewsBannerList()
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#news {
  @width: 62.5%;
  .bannner {
    width: 100%;
    margin-top: 64px;
    img {
      width: 100%;
    }
  }
  .swiper_box {
    /* width: @width; */
    width: 960px;
    min-width: inherit;
    height: 206px;
    margin: 60px auto;
    .swiper_inside {
      width: 100%;
      height: 260px;
      position: relative;
      .banner_box {
        width: 300px;
        height: 206px;
        background: #dcdcdc;
        text-align: center;
        line-height: 260px;
        cursor: pointer;
        margin: 0 auto;
        img {
          width: 300px;
          height: 206px;
        }
      }
      & /deep/ .swiper-button-prev {
        left: -48px;
        top: 70px;
        outline: none;
        width: 31px;
        height: 119px;
        background-image: url("../../assets/news/prev.png");
        background-size: 31px 119px;
      }
      & /deep/ .swiper-button-next {
        right: -48px;
        top: 70px;
        outline: none;
        width: 31px;
        height: 119px;
        background-image: url("../../assets/news/next.png");
        background-size: 31px 119px;
      }
    }
  }
  .list {
    width: 100%;
    .list_inside {
      width: 100%;
      height: 273px;
      overflow: hidden;
      .list_item {
        /* width: @width; */
        /* width: 1200px; */
        width: 1140px;
        /* margin: 32px auto; */
        margin: 32px auto;
        overflow: hidden;
        margin-bottom: 32px;
        transition: all 1s;
        cursor: pointer;
        .date_container {
          width: 74px;
          height: 210px;
          float: left;
          & div:nth-child(1) {
            font-size: 64px;
            font-weight: 500;
            color: #000000;
            margin-top: 63px;
          }
          & div:nth-child(2) {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
          }
        }
        .img_box {
          width: 310px;
          height: 210px;
          float: left;
          margin-left: 33px;
          background: #e9e9df;
          img {
            width: 310px;
            height: 210px;
          }
        }
        .text_content {
          width: calc(100% - 74px - 33px - 310px - 25px);
          margin-left: 25px;
          float: left;
          .title {
            width: 100%;
            margin-top: 9px;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //显示省略号
            white-space: nowrap; //强制不换行
            font-size: 20px;
            font-weight: 400;
            color: #000000;
          }
          .content {
            width: 100%;
            margin-top: 56px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; //显示行数
            -webkit-box-orient: vertical;
            word-break: break-all;
            font-size: 16px;
            font-weight: 400;
            color: #504d4d;
          }
          .more_btn {
            font-size: 16px;
            font-weight: 400;
            color: #999797;
            margin-top: 46px;
          }
        }
      }
      &:hover {
        background: #e6f0d1;
        .list_item {
          transform: translateX(-30px);
        }
      }
    }
  }
  .pagination {
    margin-bottom: 40px;
    margin-top: 32px;
    text-align: center;
  }
}
</style>
